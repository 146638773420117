import React from 'react';

const Logo = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 997.5 757.5"
  >
    <g>
      <path d="M261.6,234.5c3.3-3.3,6.7-6.6,10.1-9.9c4.8,0.9,9.5,1.7,14.4,2.2c21.2,2.4,46.3,2.1,66.4-5.8c11.4-4.5,28.9-17.4,21.1-31.7
      c-9.1-16.6-36.2-13.9-51.2-9.7c-20,5.6-37.2,17.3-52.8,30.6c-0.5,0.5-1.1,0.9-1.6,1.4c-27.2-6.9-52.4-20.6-73.7-39.8
      c-31.8-28.8-55.9-66.4-70.9-106.5c-3.7-9.8-6.7-19.9-9-30.2c-1.8-7.8-3.8-15.1-11.9-18.5c-12-5.1-24.7,2.4-30.2,13
      c-9.6,18.4-10.4,42.5-9.6,62.7c0.9,22.6,5.9,44.9,13.7,66.1c8.9,24.1,22.3,45.4,38.8,64.2c-7.4-7.1-14.4-14.7-21-22.6
      c-13.4-16.1-24.8-33.5-34.6-52c-6.5-12.3-17.9-36.8-35.8-26.8c-14.7,8.2-15.5,30.3-15,44.9c0.6,16,4.2,31.9,9.9,46.8
      c11.6,30,31.3,57.3,53.6,80.3c7.7,7.9,16,15.6,24.7,22.5c-3.5-2-7-4-10.5-6.2c-12.2-7.7-23.5-16.8-33.3-27.4
      c-3.6-3.9-6.7-9.2-11.5-11.9c-5.4-3-12.3-1.9-16.8,2.3c-17.6,16.4-2.6,48.7,6.9,65.2c13.1,22.9,32.6,41.4,54.3,56.1
      c13.8,9.3,28.7,17.1,44.3,23.1c-4.1-0.8-8.2-1.8-12.2-2.8c-8.4-2.1-16.8-4.6-24.5-8.5c-6.9-3.4-13.7-10-21.6-5.4
      c-12.8,7.5-4.4,29.1,0.9,38.4c10.8,19,29.7,32.7,48.8,42.6c24.5,12.6,52.2,19.9,79.9,19.1c3.3-0.1,7.7-1.4,8.4-5.2
      c0.6-3.4-3-6-6.1-5.9c-22.2,0.6-44.1-4.1-64.3-13.1c-17.9-8-36.1-19.1-48.1-34.9c-5-6.6-9.2-14.2-10.7-22.4c-0.4-2.1-0.3-4-0.2-6
      c12.2,7.3,27,11.1,40.8,14c18.4,3.8,38.1,6.2,56.8,2.7c5.5-1,10-9.3,2.1-10.8c-48.8-8.7-98.5-33.9-127.7-75
      c-6.8-9.6-12.6-20.1-16.4-31.2c-3.3-9.4-6.6-20.4-2.3-30c0,0,0.2-0.4,0.5-0.9c1.2,2,3.7,4,5,5.5c4.4,4.9,9.1,9.5,14.1,13.9
      c9.8,8.6,20.6,16.1,31.9,22.6c25.3,14.5,53.3,24,82.2,27.8c6.8,0.9,12.5-8.8,4.1-10.9c-29.8-7.3-56-24.9-78.2-45.6
      c-21.8-20.4-40.6-44.5-54-71.3c-5.9-11.8-10.5-24-13.2-36.9c-3.2-15.9-6.3-37.2,1.8-52.1c0.5-1,2-4.2,3.3-4.4
      c-0.2,0,1.8,2.1,1.9,2.1c3.3,2.9,5.7,7.9,7.9,11.7c5,8.5,9.1,17.5,14.3,25.9c9.9,16,21.3,31.2,33.8,45.2
      c25.2,28.3,56.5,51.6,93.3,62.1c5.8,1.7,14.9-5.4,7.5-10c-39.2-23.9-74.7-56.9-93.9-99.2C86.5,146.9,80.2,124,78,100.8
      c-1.3-13.4-1.2-27,0.7-40.3C80,51,81.5,40.3,86.4,32c2.3-3.9,6.5-8.3,10.5-3.7c2.5,2.9,2.6,7.7,3.4,11.3c1.3,5.5,2.8,10.9,4.5,16.3
      c6.6,21.1,15.7,41.3,26.9,60.3c22.2,37.5,53.1,72.2,92.5,92c10.6,5.3,21.7,9.7,33.1,13c-12.6,12-24.1,25.1-33.2,40
      c-24.5,40.4-31.2,90.6-26.7,137c7.9,82,54.9,157,122.4,203.4c8.4,5.8,17.2,11.1,26.2,16c6.3,3.4,17.2-5,9.1-9.3
      c-70.3-38.2-123-106.6-139-185.3c-9-44.1-7.5-93.2,9.9-135.1C234.2,267.8,246.2,249.9,261.6,234.5z M313.1,195
      c9.3-4.2,19.6-7.7,30-7.3c5.8,0.2,18,2.2,17.6,10.2c-0.3,7.1-9.2,11.6-14.8,13.7c-10.9,4-22.9,4.7-34.4,5
      c-9.7,0.2-19.3-0.4-28.7-1.9C292.1,206.9,302.1,199.9,313.1,195z M81.4,408.6c0-0.1,0.1-0.2,0-0.2
      C81.5,408.5,81.5,408.6,81.4,408.6z"
      />
      <path d="M642,613.1c3.3-0.5,5.5-3.7,4.2-6.8c-1.5-3.5-6.1-4.5-9.5-4c-29.7,4.3-57.8,11.8-85.2,24.3c-25.4,11.6-49.3,26.4-71.9,42.7
      C457,685.5,436.4,704.3,414,721c-10.3,7.7-22.7,15.8-35.8,17.4c-12.2,1.5-23.2-6.8-30.6-15.7c-8-9.6-13.2-21.1-21.6-30.5
      c-5.8-6.5-12.4-11.9-19.6-16.5c7.9-1.9,15.6-5.4,22.3-8.9c18.5-9.7,34.2-23.6,49.9-37c17.5-15,36-28.7,55.4-41
      c40.2-25.4,83.9-45.4,129.2-60c52.8-17,108.8-30.1,164.6-26.3c3.6,0.2,7.7-1.2,9.3-4.7c1.3-2.8,0.1-6.7-3.5-7
      c-75.1-5.1-152.3,16.2-221.2,44.9c-34.4,14.3-67.3,31.9-98.2,52.7c-15,10.1-29.4,21-43.1,32.8c-12.1,10.3-24.1,21.1-37.5,29.8
      c-13.3,8.7-39,23.5-51.5,6.4c-5.5-7.5-5.9-18.2-3.4-26.8c1.2-4,3-7.9,5-11.5c0.2-0.4,0.4-0.7,0.6-1.1c0.6,0.5,1.3,1,1.9,1.5
      c4.1,3.1,8.2,6.2,12.4,9.2c6.8,5,13.7,9.9,20.4,14.9c5.6,4.2,17.5-4.5,11.4-9c-11.3-8.4-22.8-16.4-34-25c-4.6-3.5-8.7-8.7-15-5.7
      c-5.4,2.6-8.8,9.6-11.4,14.6c-8,15.6-11,38.2,3.7,51.1c2.9,2.6,6,4.4,9.3,5.6c0.6,0.8,1.6,1.5,2.9,2.1
      c11.9,5.6,22.3,12.9,30.7,23.1c7.6,9.2,12.6,20.2,20.5,29.2c7.6,8.7,18.4,16.3,29.7,19c12.1,2.9,24.7-0.5,35.7-5.7
      c24.9-11.8,45-32.7,66.6-49.5c23.1-18,47.3-34.9,73.3-48.6C574,628.4,606.7,618.2,642,613.1z"
      />
      <path d="M972.3,223.8c6.5-13.7,11.1-28,13.5-43c2.6-16.6,4.2-39-6.1-53.6c-3.6-5-8.7-8.3-14.9-8.1c-9.8,0.4-15.7,8.7-20.4,16.2
      c-5,8-9,16.6-13.6,24.8c-5.3,9.3-11.4,18.2-17.8,26.9c-11.7,15.9-25.1,31.1-40.2,44c18.3-18.8,33.6-40.4,43.7-64.7
      c10.2-24.5,16.5-50.8,17.3-77.4c0.4-14-0.6-28-3.6-41.7c-1.8-8.5-4.3-17.8-10.1-24.5c-10.4-11.9-31.7-9.5-36.6,6.5
      C880,40.5,878,52.1,874,63.2c-4.3,12.1-9.5,23.8-15.5,35.2c-23.5,44.9-59.4,86.9-107.2,106.3c-7.1,2.9-14.3,5.2-21.6,7
      c-12.2-10.8-25.5-20.4-40.2-26.8c-18.4-8.1-48.4-15.4-64.2,1.8c-14.8,16.1,9.2,32,22.6,36.2c23.5,7.3,51.9,6.6,75.9,1.7
      c0,0,0.1,0,0.1,0c13.3,12.1,25.4,25.8,34.7,41c24.4,40.2,31.9,92.4,24.3,138.5c-5.5,33.7-18.5,65.9-35,95.6
      c-41.3,74.4-107.3,135.7-185,170.5c-10.9,4.9-21.9,9.2-33.2,13.1c-9.1,3.2-2.4,12.7,5.5,9.9c52.1-17.9,99.9-46.6,140.7-83.3
      c8.1-1,16.9,0.4,23.5,5.6c8.3,6.5,6,18.4,3.1,27.2c-2.1,6.5,4.2,10.8,9.1,5.6c24.6-26.4,48.1-54.5,68.1-84.6
      c6.8-10.2,15.7-22.4,15-35.3c-0.9-16.4-17.2-25.1-31.3-28.8c12.4-22.8,22.5-47,29.1-72c11.2-42.3,10.1-88.6-3.6-130.3
      c-8-24.3-20.9-46.7-38.5-65.3c-3.6-3.8-7.3-7.6-11.1-11.3c20.1-5.9,39.3-15.1,56.2-27.4c41.7-30.3,72.8-75.7,90.8-123.6
      c4.2-11.1,7.7-22.5,10.2-34.1c0.7-3.1,1.1-9,5.3-9.3c2.3-0.1,4.7,2.1,6.1,3.7c6.7,8.1,8.1,21.2,9.5,31.2
      c2.1,15.1,2.1,30.5,0.2,45.6c-3.3,26.6-11.6,52.9-24.6,76.3c-13,23.5-31.8,43.4-52.3,60.4c-10.8,9-22.4,17.1-34.4,24.4
      c-7.9,4.8,1.2,10.8,7.2,9.1c32.9-9.3,61.5-29.4,85.2-53.7c12.5-12.8,23.7-26.9,33.8-41.6c5.3-7.6,10.2-15.4,14.5-23.7
      c4.4-8.3,8.4-17.7,14.6-24.9c1.2-1.4,3.3-2.1,2.5-2.8c1.2,0.8,2.2,2.3,2.9,3.4c1.9,2.8,3,6.1,3.8,9.4c2.3,9.5,2.3,19.5,1.4,29.2
      c-1.4,14.2-4.8,28.1-10.4,41.2c-11.5,27.2-29.2,51.8-49.7,72.9c-23.4,24.2-52.4,45.8-85.6,54c-8.8,2.2-7.6,12.1,1.7,10.9
      c25.6-3.3,50.3-11.5,73.2-23.2c11.9-6.1,23.2-13.3,33.7-21.6c4.5-3.6,8.9-7.3,13.1-11.3c2.1-2,4.2-4.1,6.1-6.2
      c1.4-1.5,5.8-5.1,6.8-7.4c0.1,0.1,0.1,0.2,0.2,0.3c1.2,2,1.8,4.5,2.3,6.8c1,5.2,0.4,9.6-0.8,14.6c-2.8,11.6-7.5,22.6-13.7,32.8
      c-12.7,21-31.3,38.1-51.8,51.4c-24.1,15.6-51.3,26.1-79.5,31.2c-5.6,1-9.9,9.3-2.1,10.8c16.3,3.1,33.3,1.8,49.5-1
      c15.4-2.6,31.1-6.5,45.2-13.5c0.9-0.4,3.3-1.3,5.1-2.3c0.1,0.4,0.2,0.7,0.2,1c0.4,7.2-2.7,14.7-6.2,20.9
      c-9.6,16.8-26.4,28.8-43.2,37.5c-22.1,11.5-47,18-72,17.3c-8.3-0.2-11.9,10.9-2.2,11.1c24.6,0.7,49.2-4.7,71.6-14.6
      c20-8.9,39.8-21.3,53.3-39c7.8-10.2,18.7-29.6,11.3-42.8c-4.6-8.2-13.8-4.9-20.1-1c-6.4,4-13.6,6.6-20.8,8.9
      c-8.3,2.6-17.3,4.9-26.4,6.4c38.8-14.3,74.5-38.7,97.7-72.7c11.8-17.4,26.6-45.3,17.4-66.5c-5-11.6-17.5-13.6-26.1-4.4
      c-9.8,10.5-19,20.5-30.8,29c-8.2,5.9-16.9,11.2-26,15.9c7.4-5.5,14.5-11.5,21.2-17.8C938,279.8,958.5,253.1,972.3,223.8z
      M678.9,216.3c-11.7-0.7-25.5-1.8-35.4-8.7c-5.2-3.6-11.9-11.5-4.6-16.5c9.7-6.7,24.7-3,34.8,0.5c11,3.8,21.2,9.7,30.5,16.7
      c2.9,2.2,5.8,4.4,8.6,6.7C701.7,216.5,690.4,216.9,678.9,216.3z M781.7,537.9c-3.1,10.2-11.3,20.1-17.4,28.7
      c-14.5,20.4-30.3,40-46.9,58.7c0.1-7.1-1.6-14-6.6-19.2c-5.4-5.5-12.7-8.7-20.4-9.9c20.8-20.5,39.4-43.1,55.5-67.6
      c3.5-5.3,6.9-10.8,10.2-16.3c0.4,0.3,0.9,0.5,1.5,0.6C768.8,515.8,786.1,523.5,781.7,537.9z"
      />
      <path d="M403.2,649.4c4.2-2.2,0.5-8.6-3.7-6.4c-13.4,7.1-19,23.1-9.1,35.9c9.5,12.3,28,10.7,37.9-0.2c3.2-3.5-2-8.7-5.2-5.2
      c-6.9,7.6-19.9,9.8-26.8,0.9C389.1,665.1,393.7,654.5,403.2,649.4z"
      />
      <path d="M459.2,653.7c9.8-8,13.4-26.1,2.9-35c-6.8-5.8-21.1-8.4-28.6-2.2c-0.9-0.1-1.9,0.2-2.8,1c-9,8.2-11.3,23.1-3.4,32.9
      C434.9,659.7,449.9,661.4,459.2,653.7z M432.1,644.4c-3.6-5.7-2.8-12.7,0.7-18.1c1.4,0.3,2.8-0.1,3.6-1.8c3.5-7.3,18-4.1,22.2,1
      c6,7.3,0.8,20.7-7.1,24.3C444.9,652.9,436,650.7,432.1,644.4z"
      />
      <path d="M488.9,611c5.4-2.7,10.7-5.3,16.1-8c4.2-2.1,0.5-8.5-3.7-6.4c-5.4,2.7-10.7,5.3-16.1,8c-0.8,0.4-1.2,0.9-1.5,1.5
      c-2.3-3.7-4.6-7.3-7.1-10.9c6.9-4.2,14-7.7,21.6-10.5c4.4-1.6,2.5-8.8-2-7.1c-9,3.4-17.6,7.6-25.8,12.8c-1.7,1.1-2,2.8-1.5,4.2
      c-0.4,0.9-0.4,2,0.4,3.1c6.9,9.9,13,20.2,18.4,31c2.1,4.2,8.5,0.5,6.4-3.7c-2.3-4.6-4.7-9.1-7.3-13.6
      C487.5,611.4,488.2,611.4,488.9,611z"
      />
      <path d="M552.6,579.7c4.4-1.7,2.5-8.9-2-7.1c-7,2.8-14,5.6-20.7,9.1c-0.1,0-0.1,0.1-0.2,0.1c-1.9-3.7-4-7.3-6.2-10.8
      c7.5-3.6,15-7.2,22.5-10.6c4.3-2,0.6-8.3-3.7-6.4c-8.3,3.8-16.6,7.8-24.8,11.7c-0.7,0.1-1.3,0.5-1.9,0.9c-0.3,0.1-0.6,0.3-0.8,0.4
      c-3.6,1.7-1.5,6.5,1.8,6.7c5.8,9.4,11,19.3,15.3,29.5c1.8,4.3,8.9,2.4,7.1-2c-1.9-4.4-3.8-8.8-5.9-13.1c0.2-0.1,0.4-0.1,0.6-0.2
      C539.8,584.8,546.2,582.2,552.6,579.7z"
      />
      <path d="M596.6,545.5c4.7-0.8,2.7-7.9-2-7.1c-9,1.5-17.7,3.8-26.2,7c-4.4,1.7-2.5,8.8,2,7.1c0.3-0.1,0.6-0.2,0.9-0.3
      c3.5,9.7,6.2,19.5,8.1,29.7c0.1,0.5,0.3,0.9,0.5,1.2c0.2,2.1,1.9,4,4.4,3c6.9-2.9,14-5.5,21.5-6.3c4.7-0.5,4.7-7.9,0-7.4
      c-6.9,0.7-13.3,2.6-19.7,5c-0.7-3.5-1.4-6.9-2.3-10.3c5.3-1.9,10.8-3.3,16.3-4c4.6-0.6,4.7-8,0-7.4c-6.3,0.8-12.4,2.1-18.3,4.2
      c-1-3.5-2.2-6.9-3.4-10.3C584.2,547.9,590.3,546.5,596.6,545.5z"
      />
      <path d="M653.9,539.9c4.7,0.4,4.7-7,0-7.4c-9.2-0.8-18.4-0.6-27.6-0.2c-1.3,0.1-2.3,0.7-2.8,1.5c-0.9,0.8-1.4,2-1,3.5
      c3,11.1,4.3,22.2,3.8,33.7c0,0.2,0,0.4,0,0.5c-0.2,2.3,1.6,5,4.6,4.4c7.4-1.4,14.8-1.8,22.3-1.4c4.7,0.3,4.7-7.1,0-7.4
      c-6.5-0.4-13,0-19.5,0.9c0.1-3.6,0-7.3-0.3-10.9c6.1-0.1,12.3-0.2,18.4-0.3c4.7-0.1,4.8-7.5,0-7.4c-6.4,0.1-12.8,0.2-19.2,0.3
      c-0.5-3.5-1.1-6.9-1.9-10.4C638.4,539.3,646.2,539.2,653.9,539.9z"
      />
      <path d="M542.2,444.1c-3.3-7.5-9-13.6-16.1-17.3c9.9-14.1,11.8-30.8,5.2-45.9c-7.9-18.1-27.1-29-51.3-29c-5.7,0-11.6,0.6-17.4,1.8
      c0.2-2.1-0.8-4.8-4.3-6.4c-1.8-0.8-2.4-1.7-3.1-3.4c-0.7-1.7-1.1-4.5-1-7.5l1.5-99.3c0.4-22.3,9.2-41,21.9-46.5
      c3.3-1.4,6.3-2.2,8.9-2.2c4.6,0,8.1,2.2,9.9,6.3c1.7,3.9,1.2,11.4-5.2,14.9c-2.8,1.1-4.2,3.8-3.7,6.8c0.5,2.6,2.7,5.3,5.9,5.3
      c0.9,0,1.8-0.2,2.8-0.7c13.9-6.6,15.9-22.5,12-31.5c-3.8-8.8-11.5-13.9-20.9-13.9c-4.5,0-9.5,1.1-14.7,3.4
      c-12.9,5.6-28.6,21-29.5,62.3l-0.9,36.7c0.1,2.5-0.9,4-3.5,5.2l-52,22.7c-0.7,0.3-1.3,0.5-1.7,0.5c-0.3,0-0.4-0.1-0.5-0.2
      c-0.2-0.2-0.4-0.8-0.4-2.2l0.1-6.1l0.1-4.1c0.5-17.1,1-33.2,4.6-47.1c6.4-1,13.1-2.4,19.2-5c4.8-2.1,8.7-5.5,10.9-9.5
      c2.4-4.4,2.6-9.1,0.6-13.6c-1.9-4.4-6.4-7-12.1-7c-3.3,0-6.9,0.8-10.7,2.5c-7,3.3-12.9,10.4-16.9,20.5l-0.3,0.1
      c-18.2,2.9-30.2,4.8-43.3,10.5c-30.3,13.2-43.1,36-48.5,52.8c-6.3,19.5-5.7,42,1.6,58.6c9.9,22.8,30.1,36.3,54,36.3
      c0.5,0,1.1,0,1.6,0l0-0.1c0.1,0,0.2,0,0.3,0c1,0,2.1-0.5,2.9-0.8c3.2-1.4,4.9-5.4,3.6-8.4c-0.8-1.8-2.7-4-6.9-4l-0.8,0
      c-18.8,0-35.2-10.8-42.8-28.2c-6.2-14.3-6.5-32.9-0.8-49.8c4.8-14.2,16-33.4,41.9-44.8c7.9-3.5,18.8-6,33.2-7.9
      c-2.2,12.2-3.7,30.4-3.9,49.2l-0.2,10.7c-0.1,2.7-1,4.1-3.5,5.2L364,316c-4.2,1.8-5.5,5.6-4.9,8.5c0.6,2.7,2.6,4.4,5.2,4.4
      c1,0,2-0.2,3-0.7l0.8-0.3c0.8-0.3,1.4-0.5,1.8-0.5c0.4,0,0.5,0.1,0.6,0.2c0.2,0.2,0.4,0.7,0.4,2.1l0,3.2
      c-0.1,36.9-2.7,66.3-35,80.2c-4.5,2-9.2,3.1-13.2,3.1c-4.1,0-7.3-1.1-9-3.2c-1.4-1.8-3.3-2.8-5.4-2.8c-2.5,0-4.9,1.4-6.1,3.6
      c-1.1,2-0.8,4.4,0.6,6.6c4.2,5.5,11.3,8.5,20.1,8.5c5.9,0,12.2-1.4,18.2-4c40.7-17.8,41.4-52.6,42.2-93l0.2-7
      c0.1-2.7,1-4.1,3.5-5.2l52-22.7c0.8-0.3,1.4-0.5,1.8-0.5c0.4,0,0.5,0.1,0.6,0.2c0.2,0.2,0.4,0.7,0.4,2.1l-0.5,38.1
      c0,4.3,0.3,8.7,1.9,12.4c1.8,4.1,4.4,6.6,7.1,8.1c-2.1,0.7-4.1,1.5-6.1,2.4c-36.7,16-53,40.4-60.3,58.1
      c-8.6,20.8-9.2,43.5-1.6,60.8c8.6,19.7,25.1,31.5,44.2,31.5c0.9,0,1.8,0,2.7-0.1c4.9-0.5,7-3.9,6.9-6.8c-0.1-3.1-2.7-6.2-7.3-6.2
      l-0.6,0c-14.7,0-27.3-8.8-33.7-23.5c-6.5-15-6-34.5,1.5-52.2c6.4-15.2,21-36.3,53.8-50.6c6.8-3,14.1-4.9,22.4-5.9
      c-5.8,10.6-6,31.1-6.3,49.2c0,1.8,0,3.6-0.1,5.3l-0.3,17l-0.1,0c-0.2,6.9-0.3,12.6-0.3,17.5l0.1,0c-0.5,21.4-1.2,45.5-11.7,61.3
      c-4.5-0.2-8.5-0.4-12.6-0.4c-11.4,0-19.7,1.4-26.7,4.4c-10.4,4.5-16.3,14.7-13.1,22.2c2.4,5.6,7.7,8.4,15.5,8.4
      c8.2,0,16.3-3,18.6-4c11-5,18.6-10.5,24.9-17.7c3.2,0.1,5.8,0.2,8.3,0.2c10.2,0,23.5-0.7,36.7-6.5c15.5-6.8,27.9-19.1,34.9-34.8
      C546.8,472.1,547.6,456.5,542.2,444.1z M402,225.7c2.2-0.9,3.8-1.4,5-1.4c0.7,0,1.1,0.2,1.2,0.5c0.7,1.6-2.1,3.8-5.1,5.1
      c-3.3,1.4-5.7,2.2-8.2,2.5C396.9,229.2,399.4,226.8,402,225.7z M430.2,534c-2.7,1.2-9.4,3.9-13.8,3.9c-1.9,0-3.1-0.5-3.5-1.5
      c-0.3-0.7,1.3-3.3,6.6-5.8c7.4-3.2,13.6-3.5,19.3-3.5c1.1,0,2.2,0,3.3,0C438.2,529.8,434.1,532.3,430.2,534z M527.9,482.6
      c-5.7,12.1-15.8,21.9-28.5,27.4c-10.9,4.7-22.2,5.5-31.1,5.6c8.5-17.3,9-39.4,9.5-62.7l0.6-34l0-5c0-20.7,0-42.1,6.7-49.4
      c16.1,1.9,28.8,10,33.9,21.7c5.3,12,3.1,25.7-5.8,36.7c-1.2-0.1-2.4-0.2-3.7-0.2c-6.5,0-13.1,1.4-18.8,3.8
      c-7.6,3.3-13.9,10.8-10.7,18.2c1.6,3.6,6.1,5.9,11.5,5.9c2.9,0,6-0.7,8.8-1.9c6.5-2.8,12.7-6.9,17.5-11.4c5.4,2,9,5.7,11.8,12.2
      C533.9,459.1,533.3,471.2,527.9,482.6z M504.8,434.4c-1.5,1.3-4.2,3.4-8.2,5c-1.3,0.5-3.1,0.8-4.5,0.8c-0.8,0-1.2-0.1-1.4-0.2
      c1.5-2.9,9-5.6,14-5.6C504.6,434.4,504.7,434.4,504.8,434.4z"
      />
      <path d="M498.1,248.4c-3.1,0-6.5,0.8-10.1,2.4c-10.1,4.4-15.6,15.4-16.3,32.7l-1.2,37c-0.1,3.5,0.7,9.3,2.1,12.4
      c2.9,6.6,7.8,10,14.4,10c3.1,0,6.5-0.8,10.1-2.4c15.2-6.6,16-26.3,16.3-32.7l1.1-36.7c0.2-3.7-0.8-9.9-2-12.7
      C509.6,251.9,504.7,248.4,498.1,248.4z M500.9,306.9l0,0.4c-0.2,5.5-0.6,18.3-8.7,21.9c-1.4,0.6-2.7,0.9-3.9,0.9
      c-1.8,0-3.1-0.8-3.8-2.3c-0.6-1.4-1.1-4-1-6.9l0.9-36l0-1c0.2-5.9,0.5-18.2,8.5-21.7c1.3-0.6,2.5-0.9,3.6-0.9
      c1.2,0,2.1,0.4,2.9,1.2c1.4,1.4,2.2,4.3,2.2,8L500.9,306.9z"
      />
      <path d="M520,251c0.9,0,1.8-0.2,2.8-0.6c0.1-0.1,0.3-0.1,0.4-0.1c0.5,0,0.8,1,0.9,2.6l-0.9,45c-0.1,5.4,0.5,9.3,1.9,12.4
      c2.9,6.6,8,10.3,14.3,10.3c3.4,0,7-1,10.7-3.1c12-6.6,16.2-23.6,16.1-33.4c-0.3-4.1-3.1-5.9-5.8-5.9c-3,0-6.2,2.2-6.6,6.3l0,0.7
      c-0.3,5.2-0.8,17.5-8.8,20.9c-1.2,0.5-2.3,0.8-3.3,0.8c-4.9,0-5.8-6.5-5.9-9.3l0.6-49.5c0.1-2.7,1-4.1,3.5-5.2l5.6-2.5
      c4.2-1.8,5.1-5.5,4.6-8c-0.6-2.8-2.8-4.8-5.4-4.8c-0.8,0-1.6,0.2-2.4,0.5l-2.7,1.2c-0.7,0.3-1.3,0.5-1.7,0.5c-0.4,0-1,0-1.1-2.3
      l0.4-13.8c0.1-3.3-0.9-5.1-1.8-6.1c-1-1-2.4-1.6-3.9-1.6c-3.4,0-6.9,2.9-7.1,7.7l-0.6,18.8c0.1,2.5-0.9,4-3.5,5.2l-0.8,0.3
      c-4,1.8-5.2,5.5-4.6,8.4C515.4,249.3,517.4,251,520,251z"
      />
      <path d="M566.7,337.9c0.1-3.3-0.9-5.1-1.8-6.1c-1-1-2.4-1.6-3.9-1.6c-3.4,0-6.9,2.9-7.1,7.7L551.6,447c-0.1,4.5,0.7,9.7,2.1,12.8
      c2.9,6.8,7.6,10.2,13.9,10.2c2.9,0,6.1-0.8,9.6-2.3c5.7-2.5,9.6-7.5,12.1-13.1c2.3,1.5,5,2.3,8.1,2.3c0,0,0,0,0,0
      c3.1,0,6.5-0.8,10.1-2.4c5.5-2.4,9.6-6.8,12-12.8c2.3,1.5,4.9,2.3,7.8,2.3c2.9,0,6.1-0.8,9.5-2.2c6.8-3,11-9.6,13.4-16.5
      c3.1,2,6.8,3.1,11,3.1c4.2,0,8.6-1,13.2-3c14.6-6.4,22.6-22.2,23.2-34.7c0.1-0.6,0.2-1.2,0.2-1.8l0.9-39.1
      c1.1-9.8,5.6-17.5,11.7-20.2c1-0.4,1.9-0.7,2.7-0.7c1.8,0,3.1,1.1,4,3.1c4.6,10.6-5.1,27.7-13.6,32.9c-3.1,1.9-3.1,2.2-2.5,6.5
      c0.6,3.2,1.6,7.9,3.6,12.5c2.7,6.3,9.2,16.8,21.3,16.8c3.3,0,6.8-0.8,10.5-2.4c13.5-5.9,17.8-25.9,17.7-34.1
      c-0.3-4.1-3.1-5.9-5.8-5.9c-3,0-6.2,2.2-6.6,6.3c-0.1,1.9-1.2,18.3-10.3,22.3c-1.5,0.7-3,1-4.4,1c-4.3,0-7.9-3.3-10.9-10.2
      c-1.5-3.5-1.1-4.8,0.4-6.4c10.8-11.4,19-31.1,12.9-45.2c-3-6.9-8.2-10.7-14.5-10.7c-2.4,0-4.9,0.5-7.3,1.6
      c-3.2,1.4-6.7,4.7-9.5,8.9l2-46.3c0.1-3.3-0.9-5.1-1.8-6.1c-1-1-2.4-1.6-3.9-1.6c-3.4,0-6.9,2.9-7.1,7.7L685.2,389
      c0,0.2,0,0.3,0,0.4c-1,6.9-5.3,19.9-15.9,24.5c-2.4,1-4.5,1.5-6.5,1.5c-3.8,0-6.7-2-8.4-5.8c-1.3-3-1.9-6.6-1.7-11.2l0.8-26.5
      c0.2-7.2,0.5-18,7.7-21.1c0.9-0.4,1.8-0.6,2.7-0.6c1.3,0,2.3,0.5,2.7,1.4c1.3,2.9,1.2,6.2,1,9.6c-0.3,3.1,0.7,4.9,1.6,5.9
      c1.1,1.1,2.5,1.8,4.2,1.8c3.5,0,7.4-2.9,7.6-8.2c0.2-6.1-0.4-10.7-2.1-14.5c-2.5-5.7-7.4-8.9-13.6-8.9c-2.9,0-6,0.7-9.2,2.1
      c-6.7,2.9-14.9,10.9-15.5,32.4l-0.8,26.7c-0.1,3.6,0.1,7,0.5,10c0,0,0,0.1,0,0.1l0,0.7c-0.3,5.2-0.8,17.5-8.8,20.9
      c-1.2,0.5-2.3,0.8-3.2,0.8c-1.6,0-2.7-0.8-3.4-2.4c-0.7-1.7-1.1-4.5-1-7.5l0.8-57c-0.3-4.3-3-5.8-5.3-5.8c-1.7,0-3.4,0.8-4.6,2.1
      c-1.1,1.2-1.8,2.7-1.9,4.3c-1.8-1.1-4.1-1.7-6.8-1.7c-2.6,0-5.4,0.6-7.9,1.7c-15.2,6.6-16,26.3-16.3,32.7l-1.2,37c0,0.5,0,1,0,1.6
      c-0.3,5.6-1.1,16.9-8.7,20.2c-1.1,0.5-2,0.7-2.9,0.7c-1.1,0-1.9-0.4-2.6-1.1c-1.5-1.5-2.3-4.8-2.1-8.8L566.7,337.9z M594.7,397.9
      c0.2-5.9,0.5-18.2,8.5-21.7c1.3-0.5,2.4-0.8,3.4-0.8c1.2,0,2.3,0.4,3.1,1.3c1.5,1.6,2.4,4.5,2.3,8.2l-1.1,39.3
      c-0.1,7.1-2.5,16.3-8.6,19c-1.2,0.5-2.2,0.8-3.2,0.8c-1.3,0-2.3-0.4-3.2-1.3c-1.5-1.5-2.3-4.4-2.3-7.7L594.7,397.9z"
      />
    </g>
  </svg>
);

export default Logo;
