import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  background-color: #362d26;
  padding: 12px 0;
  text-align: center;
  color: white;
  line-height: 166%;
  border-bottom: 5px solid #edcf8f;
`

const Notification = () => (
  <Box>
    We're open! <br />
    Mon-Fri 7am-7pm | Sat-Sun 8am-7pm
  </Box>
)

export default Notification
